.reservation-section {
	background: #fafafa;
	.reservation-box {
		border: 1px solid #eee;
		margin: 1rem 0;
		.reservation-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: #f2f2f2;
			padding: 0.5rem;
			h6 {
				color: #656565;
			}
			.reserv-tabs {
				.nav-item {
					margin-right: 0.5rem;
					.nav-link {
						background: #d6d6d6;
						transition: all 0.3s ease-in-out;
						padding: 0.5rem 4rem;
						position: relative;
					}
				}
			}
			.nav-link.active h6 {
				color: #fff !important;
			}
			.nav-link.active {
				&::after {
					content: "";
					position: absolute;
					border-width: 0px 5px 5px 5px;
					border-style: solid;
					border-color: #aeaeae transparent #fff transparent;
					bottom: 0;
					right: 50%;
				}
			}
		} //reservation-header

		.reservation-table {
			font-size: $fontText;
			th {
				font-weight: normal;
			}
			.progress {
				height: 1.5rem;
				background: #d6d6d6;
			}
		} //reservation-table
	}

	.table-striped tbody tr:nth-of-type(odd) {
		background: #f2f2f2;
	}

	.pagination {
		display: flex;
		flex-flow: row-reverse;
		span,
		.page-link {
			padding: 0.5rem;
			line-height: 1;
			color: #656565;
			font-weight: bold;
			transition: all 0.3s ease-in-out;
			&:hover {
				background: $goldColor;
				color: $whiteColor;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem rgba(188, 165, 86, 0.25);
			}
		}
	}
	.table td,
	.table th {
		vertical-align: middle;
	}
} //reservation-section

.umrah-tabs .nav-link.active,
.nav-pills .show > .nav-link {
	background-color: #bca556 !important;
}
