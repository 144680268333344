.medical-section {
	.mybooking-input-wrapper .form-group .form-control-custom-input {
		border: 1px solid #dee2e6 !important;
	}
} //medical-section

.modal-dialog {
	.mybooking-input-wrapper .form-group .form-control-custom-input {
		border: 1px solid #dee2e6 !important;
	}
}

.medical-result {
	border: 1px solid #d6d6d6;
	background-color: #f2f2f2;
	min-height: 30vh;

	.no-result {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		min-height: 30vh;
		color: #585858;
	}
}

.pointer {
	cursor: pointer !important;
}

.submit-btn {
	min-width: 120px !important;
	background-color: #bca556 !important;
	outline: none !important;
	border-color: #bca556;
	height: 50px !important;
	border-radius: 5px !important;
}

.input-upload {
	label {
		width: 100%;
		cursor: pointer;
		margin-bottom: 0;
		.img-box {
			border: 2px dashed #c2cdda;
			position: relative;

			.shape {
				width: 120px;
				height: 120px;
				margin: 3px auto;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				img {
					width: 45px;
					margin: 0.1rem auto;
				}
			} //shape
		}
	}
	input {
		display: none;
	}
} //input-upload
