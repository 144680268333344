// Directions
$dir: ltr;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

html {
	--right: right;
	--left: left;
	--dir: ltr;
	--productListTranslate: 150%;
	--percentage: 100%;
}

@import "~bootstrap";

// Base Files
@import "base/variables";
@import "base/reset";
@import "base/functions";
@import "base/mixins";
@import "base/colors";
@import "base/typography";
@import "base/general";
@import "base/custom-modal";

// Shared Components
@import "shared/all";

// Modules
@import "./modules/auth/all";
@import "./modules/labs/all";
@import "./modules/labsAdmin/all";
@import "./modules//companies/all";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;700&display=swap");

body {
	font-family: "Roboto", sans-serif;
}
button {
	text-transform: capitalize;
}

html[dir="rtl"] {
	--dir: rtl;
	--left: right;
	--right: left;
	--productListTranslate: -150%;
	--percentage: -100%;

	body {
		font-family: "Cairo", sans-serif;
	}
}

.link-company {
	.far,
	.fa,
	.fas {
		font-weight: 600;
		margin: 0 5px;
	}
	font-size: 14px;
	font-weight: 500;
}
.text-gray {
	color: #656565 !important;
}
.is-active {
	background: #112233;
}
.w-100 {
	width: 100%;
}
.fa-2x {
	font-size: 20px !important;
}
.text-fix {
	text-align: left;
	[dir="rtl"] & {
		text-align: right;
	}
}
.pagination {
	direction: rtl;
	[dir="rtl"] & {
		direction: ltr;
	}
}
